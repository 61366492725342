import $ from 'jquery'
import 'magnific-popup'
import './classes/polyfills.class.js'
import ScrollBar from './classes/simplebar.js'  
import AnchorScroller from './classes/anchor-scroller'
import AOS from 'aos'
import CollapsibleItem from './classes/collapsible-item.class.js'
import FixedHeader from './classes/fixed-header.class'
import FixedIsi from './classes/fixed-isi.js'
import FormBehavior from './classes/form-behavior.class.js'
import MainMenu from './classes/main-menu.class.js'
import Carousel from './classes/carousel.class.js'
import Modal from './classes/modal.class.js'
import Violator from './classes/violator.class'

// Initialize Animate on Scroll
AOS.init({
  duration: 1200,
})

new ScrollBar(document.querySelector('.fixed .row'))

if (document.querySelector('#popup-trial-design')) {
  new ScrollBar(document.querySelector('#popup-trial-design .layout--onecol'))
}

// Initialize AnchorScroller
const scroll = new AnchorScroller(
  $('header[role=banner]').height(),
  $('header[role=banner]').height(),
  768
)
scroll.init()

$(window).on('resize', function () {
  if ($('html').hasClass('enable_sticky_desktop')) {
    scroll.desktopOffset = $('header[role=banner]').height()
  }

  if ($('html').hasClass('enable_sticky_desktop')) {
    scroll.mobileOffset = $('header[role=banner]').height()
  }
}).trigger('resize')


// Initialize Modal Class
new Modal()

$(() => {
  // Prevent scroll to modal after click
  $('a').each(function () {
    const href = $(this).attr('href')

    if (
      typeof href !== 'undefined'
      && href.startsWith('#')
      && $(href).hasClass('block--type--modal')
    ) {
      $(this).on('click', (e)=> e.stopImmediatePropagation())
    }
  })

  const isi = document.querySelector('.isi .fixed')
  if (isi) {
    new FixedIsi()
  }

  const violator = document.querySelector('.violator')
  if (violator) {
    new Violator(violator)
  }

  new FixedHeader()

  const mainMenu = document.querySelector('.layout-container > header')
  if (mainMenu) {
    new MainMenu(mainMenu)
  }

  // Don't active slideshows on the Layout Builder page
  if (
    $('.block--type--carousel-slide').length
    && !$('.node-page-layout-builder-form').length
  ) {
    $.uniqueSort(
      $('.block--type--carousel-slide')
        .parents('.layout__region')
    )
      .css({
        'min-width': 0
      })
      .each(function () {
        new Carousel(this)
      })
  }

  if ($('.clouds').length) {
    $('.clouds-bg').css('top', $('.clouds').offset().top)
  }

  if ($('.swiper').length && $(window).width() > 767) {
    var swiper = new Swiper('.swiper', {
      slidesPerView: 3,
      centeredSlides: true,
      loop: true,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    
  }

  document.querySelectorAll('.webform-submission-form').forEach(f => {
    new FormBehavior(f)
  })

  document.querySelectorAll('.block--type--collapsible-item').forEach(item => {
    new CollapsibleItem(item)
  })

  if ($('.popup-youtube').length) {
    $('.popup-youtube').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?autoplay=1&modestbranding=1'
          }
        }
      },
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    })
  }

  gsap.registerPlugin(ScrollTrigger);  

  gsap.utils.toArray('.home-hero-wrap').forEach(function(container) {
    let image = container.querySelector('.woman');
  
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        scrub: true,
        pin: false,
      },
    })
    tl.from(image, {
      yPercent: -100,
      ease: 'none',
    }).to(image, {
      yPercent: 30,
      ease: 'none',
    })
  })

  gsap.utils.toArray('.hero-wrap').forEach(function(container) {
    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        scrub: true,
        pin: false,
      },
    }); 
    tl2.to('.ballons-front', 1, {yPercent: -60}, '+=1.5');

    let tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        scrub: true,
        pin: false,
      },
    });
    tl3.to('.ballons-back', 2, {yPercent: -50}, '+=1');
  })
})
