import 'magnific-popup'
import $ from 'jquery'

// options at: https://dimsemenov.com/plugins/magnific-popup/

class Modal {
  constructor() {
    if (
      document.location.hash !== ''
      && $(document.location.hash).length
      && $(document.location.hash).hasClass('block--type--modal')
    ) {
      $.magnificPopup.open({
        items: {
          src: document.location.hash,
          type: 'inline'
        },
        overflowY: 'scroll'
      })
    }


    $('a').each(function () {
      const href = $(this).attr('href')
      const link = $(this)
      if (
        typeof href !== 'undefined'
        && href.startsWith('#')
        && href.length > 1
        && $(href).hasClass('block--type--modal')
      ) {
        $(this).magnificPopup({
          items: {
            src: href,
            type: 'inline',
          },
          //type: 'inline',
          overflowY: 'scroll',
          closeOnBgClick: false
        })
      }

      if (
        typeof href !== 'undefined'
        && href.startsWith('http')
        && $('#popleave').length
      ) {
        $(this).magnificPopup({
          items: {
            src: '#popleave' + (link.attr('link') && link.attr('link').includes('patient') ? '-patient' : '') + (link.attr('link') && link.attr('link').includes('ionis') ? '-ionis' : ''),
            type: 'inline'
          },
          callbacks: {
            open: function() {
              $('#popleave' + (link.attr('link') && link.attr('link').includes('patient') ? '-patient' : '') + (link.attr('link') && link.attr('link').includes('ionis') ? '-ionis' : '') + ' .ok').off('click')
              $('#popleave' + (link.attr('link') && link.attr('link').includes('patient') ? '-patient' : '') + (link.attr('link') && link.attr('link').includes('ionis') ? '-ionis' : '') + ' .ok').on('click', function (e) {
                e.preventDefault
                e.stopPropagation
                window.open(href, '_blank')
                $.magnificPopup.close()
                return false
              })

              $('#popleave' + (link.attr('link') && link.attr('link').includes('patient') ? '-patient' : '') + (link.attr('link') && link.attr('link').includes('ionis') ? '-ionis' : '') + ' .cancel').on('click', function (e) {
                e.preventDefault
                e.stopPropagation
                $.magnificPopup.close()
                return false
              })
            }
          }
        })
      }


      /*if (
        typeof href !== 'undefined'
        && href.startsWith('#representative')
        && $('#representative').length
      ) {
        $(this).magnificPopup({
          items: {
            src: '#representative',
            type: 'inline'
          },
          callbacks: {
            open: function() {
              $('#representative .close').on('click', function (e) {
                e.preventDefault
                e.stopPropagation
                $.magnificPopup.close()
                return false
              })
            }
          }
        })
      }

      if (
        typeof href !== 'undefined'
        && href.startsWith('#subscribe')
        && $('#subscribe').length
      ) {
        $(this).magnificPopup({
          items: {
            src: '#subscribe',
            type: 'inline'
          },
          callbacks: {
            open: function() {
              $('#subscribe .close').on('click', function (e) {
                e.preventDefault
                e.stopPropagation
                $.magnificPopup.close()
                return false
              })
            }
          }
        })
      }*/
    })
  }
}

export default Modal
