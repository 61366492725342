import $ from 'jquery'
import 'slick-carousel'

// options at: http://kenwheeler.github.io/slick/

class Carousel {
  constructor(el) {
    $(el).parent().addClass('carousel')

    $(el).slick({
      accessibility: true,
      centerMode: true,
      slidesToShow: 3,
      dots: false,
      arrows: true,
      variableWidth: true
    })
  }
}

export default Carousel
