class FixedIsi {
  constructor() {
    this.fixed_isi         = document.querySelector('.isi .fixed')
    this.fixed_isi_btn     = document.querySelector('.isi .read-more')
    this.fixed_isi_height  = this.fixed_isi.clientHeight
    this.static_isi        = document.querySelector('#isi')
    this.static_isi_offset = 0

    window.addEventListener('scroll', () => {
      this.hideShowISI()
    })

    this.fixed_isi_btn.addEventListener('click', (event) => {
      event.preventDefault()
      
      if (this.fixed_isi.classList.contains('open')) {
        this.fixed_isi.classList.remove('open')
        this.fixed_isi.classList.remove('first-open')
      } else
        this.fixed_isi.classList.add('open')
    })

    this.hideShowISI()
  }

  calculateOffset() {
    this.static_isi_offset = this.static_isi.getBoundingClientRect().top + this.fixed_isi_height - window.innerHeight + window.scrollY
  }

  hideShowISI() {
    this.calculateOffset()

    if (window.pageYOffset > this.static_isi_offset) {
      this.fixed_isi.classList.add('hidden')
    } else {
      this.fixed_isi.classList.remove('hidden')
    }
  }
}

export default FixedIsi
